<template>
    <div class="container">
        <div class="flex-wrap">
            <div class="data-item"
                 v-for="item in techTurnover"
                 :key="item.data_type">
                <h2>{{item.data_type|dataType}}</h2>
                <ul class="data-list">
                    <li>
                        <strong>{{item.today_data}}</strong>
                        <span>今日</span>
                    </li>
                    <li>
                        <strong>{{item.month_data}}</strong>
                        <span>本月</span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
	import table from '@/api/table'
	export default {
		name:"technician-state",
		data(){
			return {
				techTurnover:null
			}
		},
		filters:{
			dataType(type){
				return type == 'entry'?'新入职技师':'离职技师'
			},
		},
		methods:{
			/**
			 * 获取技师流动数据
			 */
			getTechnicianTurnover(){
				table.getTechnicianTurnover().then(res =>{
					this.techTurnover = res.data
				}).catch(err =>{
					console.log("err",err.msg)
				})
			}
		},
		created(){
			this.getTechnicianTurnover()
		}
	}
</script>
<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .container{
        padding:20px 30px 10px;
        background:linear-gradient(to bottom, #007AFF 0%, #4EBFFE 50%, #EEEEEE 50%, #EEEEEE 100%);
        position:relative;
        width:100vw;
        height:270px;
        top:-1px;
    }

    .flex-wrap{
        display:flex;
        align-items:center;
        justify-content:center;
        background:#FFFFFF;
        padding:30px 10px;
        border-radius:10px;
        height:240px;
    }

    .data-item{
        flex:1;
    }

    .data-item:first-child{
        border-right:1px solid #D1D1D1;
    }

    h2{
        color:#000;
        font-size:32px;
        text-align:center;
        font-weight:bold;
        margin:0 0 30px;
    }

    .data-list{
        display:flex;
        text-align:center;
        li{
            flex:1;
            display:flex;
            flex-direction:column;

            strong{
                color:$high-light-color;
                font-size:44px;
                font-weight:normal;
            }

            span{
                color:#ACACAC;
                font-size:22px;
            }
        }
    }
</style>
